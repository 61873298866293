$primary: #194F87;
$secondary: #FEFEFE;
$tertiary: #326396;
$my-lighten:#4080ff;
$serif-font: Palatino,Georgia,Cambria,"Times New Roman",Times,serif;
// Bootstrap: https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
$small-device-width: 576px;

.vh-100-min {
  min-height: 100vh !important;
}

body {
  margin: 0;
  font-family: $serif-font,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.responsive-embed {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Ratio */
  height: 0;
  overflow: hidden;
}
.responsive-embed iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.ant-menu-title-content {
  a:hover {
    text-decoration: none;
  }
}